.App {
  text-align: center;
}

.App-header {
  background-color: #cc367a;
  /* background-image: linear-gradient(#AC5C5C, #fff0f5 30%); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.gradient1 {
  background-image: linear-gradient(#cc367a, #fff0f5 50%);
  min-height: 100vh;
  width: 100%;
}

.gradient2 {
  background-image: linear-gradient(#fff0f5, #702888, #00091E 70%);
  min-height: 75vh;
  width: 100%;
}

.contrast {
  color:rgb(172, 92, 92);
}

.zoom {
  transition: transform .2s; /* Animation */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.section {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 600px;
  margin-left: -4rem;
}

h4 {
  color:rgb(172, 92, 92);
  margin: 2rem;
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color:darkred;
  font-weight:bolder;
}

.card {
  display: block;
  margin: 2rem;
  border-radius: 25px;
  width: 600px;
  height: 350px;
  box-shadow: 0px -10px 30px #ccc;
}

a {
  color: white;
}

footer {
  background-color: #00071D;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: column;
  text-align: left;
  
}

.cutelink {
  color:white;
}